// ==========================================================================
// Select Form Atom
// ==========================================================================

.select {
    display: block;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: rem-calc(1) solid palette('grey');
    padding: spacing(13 15);
    font-size: rem-calc(fontsize(regular));
    font-family: 'Lato', sans-serif;
    border-radius: rem-calc(3);
    margin-bottom: rem-calc(5);
    background: white;
    color: palette('black');
    &[disabled] {
        background-color: palette('lightgrey');
    }
    &:focus {
        border-color: palette('black');
    }
}


.select__wrapper {
    position: relative;
    background-color: white;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    max-width: 100%;
    .select {
        width: auto;
        position: relative;
        z-index: 2;
        display: block;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: rem-calc(1) solid palette('grey');
        padding: spacing(13 65 13 15);
        font-size: rem-calc(fontsize(regular));
        font-family: 'Lato', sans-serif;
        border-radius: rem-calc(5);
        box-sizing: border-box;
        margin-bottom: rem-calc(5);
        &[disabled] {
            background-color: palette('lightgrey');
        }
        &:focus {
            border-color: palette('black');
        }
    }

    .select::-ms-expand {
        display: none;
    }

    &:focus-within {
        .select__icon {
            border-left-color: palette('grey');
        }
    }
}

.select__wrapper--block {
    width: 100%;
    .select {
        display: block;
        width: 100%;
    }
}

// icon
.select__icon {
    pointer-events: none;
    position: absolute;
    z-index: 3;
    right: 0;
    top: 0;
    width: rem-calc(50);
    height: rem-calc(46);
    border-left: rem-calc(1) solid palette('grey');

    .chevron {
        margin: rem-calc(20 19);
    }
}
