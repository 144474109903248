.flash-message {
    background: palette('blue');
    color: white;
    text-align: left;
    padding: spacing(base 0);

    a {
        color: white;
        text-decoration: underline;
    }
}


.flash-message--error {
    background: palette('primary');
}
