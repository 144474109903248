// ==========================================================================
// Table Atom
// ==========================================================================

$_tableBorderColor: lighten(palette('grey'), 10%);

.table {
    width: 100%;
    border-collapse: collapse;

    th, td {
        text-align: left;
        padding: rem-calc(15 20);
        margin: 0;
        color: palette('black');
        font-size: rem-calc(fontsize(regular));
        border-bottom: 1px solid $_tableBorderColor;
        vertical-align: top;
    }

    th {
        font-weight: bold;
    }

    tr:nth-child(odd) {
        th, td {
            background-color: palette('lightgrey');
        }
    }
    thead tr:nth-child(odd) {
        th, td {
            background-color: transparent;

        }
    }
    thead tr:last-child {
        th, td {
            border-bottom: 1px solid $_tableBorderColor;
        }
    }

    tr:last-child {
        th, td {
            border-bottom: none;
        }
    }

    // tr:first-child {
    //     th, td {
    //         border-top: none;
    //     }
    // }
}
