// ==========================================================================
// Button Atom
// ==========================================================================

.btn {
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
    border-radius: rem-calc(30);
    padding: spacing(14 20);
    font-size: rem-calc(fontsize(small));
    border-width: 0;
    cursor: pointer;

    @include respond-to(medium) {
        font-size: rem-calc(fontsize(regular));
    }

    @include link() {
        background: palette('primary');
        color: white;
    }

    @include link-hover() {
        background: palette('black');
        outline: none;
        cursor: pointer;
    }

    // Need to slightly offset the chevron if it's in a btn
    .chevron {
        margin-bottom: rem-calc(2);

        @include respond-to(medium) {
            margin-bottom: rem-calc(3);
        }
    }
}

.btn--primary {
    @include link() {
        background: palette('primary');
        color: white;
    }

    .chevron {
        border-color: white;
    }

    @include link-hover() {
        background: palette('black');
    }
}

.btn--secondary {
    @include link() {
        background-color: white;
        color: palette('primary');
        border: 2px solid palette('primary');
    }

    .chevron {
        border-color: palette('primary');
    }

    @include link-hover() {
        color: palette('black');
        background-color: white;
        border: 2px solid palette('black');

        .chevron {
            border-color: palette('black');
        }
    }
}

.btn--black {
    @include link() {
        background-color: white;
        color: palette('black');
        border: 2px solid palette('black');
    }

    .chevron {
        border-color: palette('black');
    }

    @include link-hover() {
        color: palette('primary');
        background-color: white;
        border: 2px solid palette('primary');

        .chevron {
            border-color: palette('primary');
        }
    }
}


.btn--naked {
    @include link() {
        border: none;
        background-image: none;
        background-color: transparent;
        color: palette('primary');
    }

    @include link-hover() {
        color: black;
        background-color: transparent;
    }
}

.btn[disabled] {
    color: palette('grey');
    border-color: palette('grey');
}

.btn--block {
    display: block;
    width: 100%;
}

.btn--inline {
    display: inline-block;

    & + .btn--inline {
        margin-top: spacing(base);
    }
}

.btn--small {
    padding: spacing(8 18);
}

.btn__group {
    .btn + .btn, a + a {
        @include link() {
            margin-left: spacing(base);
        }
    }
}

