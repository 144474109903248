
$colours-map: (
  // Primary
  'primary': (rgb(255, 90, 82)),
  // notification green
  'green': (rgb(76, 164, 101)),
  // Secondary Hover
  'secondaryhover': (rgb(18, 25, 135)),
  // Tertiary Hover
  'tertiaryhover': (rgb(138, 100, 51)),
  // notification red
  'red': (rgb(204, 72, 74)),
  // Tertiary
  'tertiary': (rgb(204, 179, 143)),
  // notification yellow
  'yellow': (rgb(242, 180, 69)),
  // Secondary
  'secondary': (rgb(24, 34, 186)),
  // Light Grey used for backgrounds
  'lightgrey': (rgb(250, 250, 250)),
  // notification blue
  'blue': (rgb(29, 148, 250)),
  // Grey
  'grey': (rgb(203, 203, 204)),
  // Near black, used for copy
  'black': (rgb(77, 78, 93)),
);
