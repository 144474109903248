.m-pagination {
	display: flex;
	flex-wrap: wrap;

	.node {
		margin: spacing(2 2);

		@include respond-to(medium) {
			margin: spacing(3 3);
		}
	}
}