.m-pagetitle {
    padding: spacing(triple) 0;

    @include respond-to(medium) {
        padding: spacing(quad) 0;
    }

    h1 {
        padding: 0;
        margin: 0;
    }
}
