.logo {
	width: rem-calc(150);
	
	@include respond-to(small) {
		width: rem-calc(180);
	}

	@include respond-to(medium) {
		width: rem-calc(240);
	}
}