.m-accordion__content {
    display: none;
    padding-top: spacing('double');
    &.is--open,
    .no-js & {
        display: block;
    }
}

.m-accordion__item {
    & + .m-accordion__item {
        padding-top: spacing(double);
    }
}

.m-accordion__title {
    display: block;
    position: relative;
    border-bottom: 1px solid palette('grey');
    padding-bottom: spacing(base);

    & + & {
        padding-top: 0;

        .m-accordion__title {
            padding-top: spacing(base);
        }
    }
    &.is--open {
        .m-accordion__chevron {
            transform: rotate(225deg);
        }
    }
}

.m-accordion__chevron {
    position: absolute;
    margin: rem-calc(-12 0 0 5);
    left: 0;
    top: 50%;
}

.m-accordion__title-text {
    margin: 0;
    padding-left: spacing(double);

}
