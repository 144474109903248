.notification {
    @include trim();
    background: lighten(palette('blue'), 42%);
    border: 1px dashed palette('blue');
    border-radius: 4px;
    padding: spacing(double);
    margin-bottom: spacing(double);
    > *:last-child {
        padding-bottom: 0;
    }
}

.notification--warning {
    background: lighten(palette('yellow'), 36%);
    border: 1px dashed palette('yellow');
}

.notification--error {
    background: lighten(palette('red'), 43%);
    border: 1px dashed palette('red');
    color: palette('primary');
}

.notification--success {
    background: lighten(palette('green'), 51%);
    border: 1px dashed palette('green');
    color: palette('green');
}
