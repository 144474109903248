// ==========================================================================
// WRAPPER LAYOUT
// ==========================================================================

// CONFIG + SETTINGS
// Max-width of the wrapper module regardless of the breakpoint,
$_wrapper-max-width: 1250;
$_wrapper-outer-max-width: 1600;

// Gutter width of the wrapper to aid legibility on smaller devices.
$_wrapper-padding: 25;
$_wrapper-padding-large: 40;

.wrapper {
    clear: both;
    margin: 0 auto;
    position: relative;
    padding-left: rem-calc($_wrapper-padding);
    padding-right: rem-calc($_wrapper-padding);
    max-width: rem-calc($_wrapper-max-width);

    @include respond-to(medium) {
        padding-left: rem-calc($_wrapper-padding-large);
        padding-right: rem-calc($_wrapper-padding-large);
    }

    // make the padding smaller at the max-width breakpoint
    @media screen and (min-width: 1300px) {
        padding-left: rem-calc($_wrapper-padding);
        padding-right: rem-calc($_wrapper-padding);
    }
}

.wrapper-outer {
    @media screen and (min-width: rem-calc($_wrapper-outer-max-width)) {
        clear: both;
        margin: 0 auto;
        position: relative;
        max-width: rem-calc($_wrapper-outer-max-width);
    }
}

.wrapper--outer > .wrapper {
	padding-left: 0;
    padding-right: 0;
}

.wrapper--skinny {
    max-width: rem-calc(map-get($breakpoints-map, 'medium'));
}
