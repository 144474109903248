.m-slideshow__inner {
	position: relative;
}

.m-slideshow__image-container {
	background-color: palette('lightgrey');
	width: 100%;
    text-align: center;
}

.m-slideshow__image {
	width: auto;
	max-height: rem-calc(450);

	&.is--queued {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}

	&.is--active {
		position: relative;
		opacity: 1;
	}
}

.m-slideshow__controls {
	position: absolute;
	bottom: 0;
	right: 0;
	background: white;
	padding: spacing(half);

	.node:last-of-type {
		margin-left: spacing(half);
	}
}

.m-slideshow__figcaption {
	text-align: right;
	font-weight: 600;
}
