.m-vertical-nav {
    li {
        margin-bottom: 0;
    }
}

.m-vertical-nav__item {
    display: block;
    padding: rem-calc(0 0 7 0);
    text-decoration: underline;
    font-weight: 400;

    @include link() {
        color: palette('black');
    }

    @include link-hover() {
        color: palette('primary');
    }

    &:before {
        margin-bottom: rem-calc(2);
        margin-right: rem-calc(7);
        content: ' ';
        @include chevron;
    }

    &.is--active {
        @include link() {
            background: none;
            color: palette('primary');
            font-weight: bold;
        }
    }
}
