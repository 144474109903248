.lede,
.lede > p {
    font-size: rem-calc(20);
    line-height: rem-calc(30);
}

.lede--fancy > p {
    &:first-of-type {
        font-size: rem-calc(26);
        line-height: rem-calc(34);
        font-family: 'Frank Ruhl Libre', serif;
        color: palette('secondary');
    }
}
