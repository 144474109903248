.meta {
    font-size: rem-calc(fontsize(xsmall));
    color: palette('tertiaryhover');
    letter-spacing: 0.5px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: rem-calc(21);
}

.meta--title {
	font-size: rem-calc(fontsize(small));
	color: palette('secondary');
	letter-spacing: 2px;
}
