// ==========================================================================
//  Layout Alignment
// ==========================================================================

/*doc
---
name: Alignment
category: layout
---

`.g--align-v-bottom / .g--align-v-top / .g--align-v-center` Vertically aligns cells

```html
<div class="g g--align--bottom" style="height: 300px;">
  <div class="g__cell">
      <div class="card">
        <h2>We're aligned bottom vertically</h2>
        <p>lorem ipsum dolor sit amet consecteteur adipicising elit aram lorem ipsum.</p>
      </div>
  </div>
  <div class="g__cell">
      <div class="card">
        <h2>We're aligned bottom vertically</h2>
        <p>lorem ipsum dolor sit amet consecteteur adipicising elit aram lorem ipsum.</p>
      </div>
  </div>
</div>
```

applying `.align-justify-center` will horizontally align cell content from the center

```html
<div class="g g--align--justify--center">
  <div class="g__cell">
      <div class="card">
        <h2>We're aligned from the middle horizontally</h2>
        <p>lorem ipsum dolor sit amet consecteteur adipicising elit aram lorem ipsum.</p>
      </div>
  </div>
  <div class="g__cell">
      <div class="card">
        <h2>We're aligned from the middle horizontally</h2>
        <p>lorem ipsum dolor sit amet consecteteur adipicising elit aram lorem ipsum.</p>
      </div>
  </div>
</div>
```
*/

.g--align-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.g--align-justify-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.g--align-right {
    margin-left: auto;
}

.g--align-v-top {
    align-items: flex-start;
}

.g--align-v-bottom {
    align-items: flex-end;
}

.g--align-v-center {
    align-items: center;
}

@each $bp, $config in $breakpoints-map {
    @include respond-to($bp) {
        .g--align-end--#{$bp} {
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
        }

        .g--align-justify-center--#{$bp} {
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
        }

        .g--align-right--#{$bp} {
            margin-left: auto;
        }

        .g--align-v-top--#{$bp} {
            align-items: flex-start;
        }

        .g--align-v-bottom--#{$bp} {
            align-items: flex-end;
        }

        .g--align-v-center--#{$bp} {
            align-items: center;
        }
    }
}
