.m-footer {
    background: palette('black');
    padding: spacing(triple) 0;

    @include respond-to(medium) {
        padding: spacing(quad) 0;
    }

    p {
        font-weight: 400;
    }

    a.link {
        @include link-hover() {
            color: palette('primary');
        }
    }

    // @TODO / Shame - this is gross...
    .m-accordion__title {
        @include link-hover() {
            cursor: pointer;

            .chevron {
                border-color: palette('primary');
            }
        }
    }
}

.m-footer--minimal {
    padding: spacing(base) 0;
}

.m-footer__title {
    font-family: 'Frank Ruhl Libre', serif;
    font-size: rem-calc(fontsize(medium));
    font-weight: 600;
    display: block;
}
