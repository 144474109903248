// ==========================================================================
// Control Atom
// ==========================================================================

.control {
    padding-bottom: rem-calc(20);

    &.has--success {
        .control__message {
            color: palette('green');
        }

        .input {
            border-color: palette('green');
            color: palette('green');
        }
    }

    &.has--error {
        .control__message {
            color: palette('red');
        }

        .input {
            border-color: palette('red');
            background-color: lighten(palette('red'), 32%);
        }
    }
}

.control__errors {
    color: palette('red');
}

.control__caption {
    @include trim();
    font-size: rem-calc(fontsize(small));
    font-style: italic;
    color: palette('black');
    display: block;
    padding: rem-calc(5 0);
}


.control__message {
    @include trim();
    font-size: rem-calc(fontsize(small));
    color: palette('black');
    display: block;
    padding: rem-calc(0 0 5);
}
