.m-teaser__title {
    margin-top: rem-calc(2);
    margin-bottom: spacing(base);
}

.m-teaser__link {
    display: block;
    margin-top: spacing(base);
}

.m-teaser__image {
    margin-bottom: spacing(base);
}