.m-horizontal-nav {
}

.m-horizontal-nav__item {
    display: inline-block;
    padding: rem-calc(10) rem-calc(15);
    text-decoration: none;
    font-weight: 700;

    @include link() {
        color: palette('black');
        font-size: rem-calc(fontsize(small));

        @include respond-to(medium) {
            font-size: rem-calc(fontsize(regular));
        }
    }

    @include link-hover() {
        color: palette('primary');
    }

    .chevron {
        margin-bottom: rem-calc(2);
        margin-left: rem-calc(2);
    }

    &.is--active {
        @include link() {
            background: palette('primary');
            color: white;
            border-radius: rem-calc(20);
        }

        .chevron {
            border-color: white;
        }
    }
}

// @TODO @SG - Move this to it's own atom or rename better?
.login-nav__item {
    display: block;
    position: absolute;
    top: 0;
    right: 35px;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 0 0 5px 5px;

    @include link() {
        background: palette('primary');
        color: white;
        text-decoration: none;
    }

    @include link-hover() {
        background: palette('black');
    }
}

.login-nav__item--mobile {
    display: block;
    position: relative;
}
