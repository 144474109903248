@import '~@mise-en-place/tools.link';
@import '~@mise-en-place/tools.strip-units';
@import '~@mise-en-place/tools.trim';
@import '~@mise-en-place/utilities.bg-cover';
@import '~@mise-en-place/utilities.scroll';
@import '~@mise-en-place/utilities.uppercase';

// Tools
@import 'tools/rem-calc';
@import 'tools/map-get-if-exists';
@import 'tools/map-deep-get-set';
@import 'tools/placeholder';

@import 'tools/palette';
@import 'design/colours.map';
@include palette-load($colours-map);

@import 'tools/breakpoint';
@import 'design/breakpoints.map';
@include breakpoint-load($breakpoints-map);

@import 'tools/font-size';
@import 'design/fontsizes.map';
@include fontsize-load($fontsizes-map);

@import 'tools/spacing';
@import 'design/spacing.map';
@include spacing-load($spacing-map);

// Normalize
@import 'normalize';

// Layout
@import 'layout/wrapper/wrapper';
@import 'layout/alignment/alignment';
@import 'layout/grid/grid';

// Forms
@import 'forms/control/control';
@import 'forms/form/form';
@import 'forms/input/input';
@import 'forms/label/label';
@import 'forms/checkbox/checkbox';
@import 'forms/radio/radio';
@import 'forms/select/select';
@import 'forms/textarea/textarea';

// Atoms
@import 'atoms/chevron/chevron';
@import 'atoms/btn/btn';
@import 'atoms/list/list';
@import 'atoms/island/island';
@import 'atoms/blockquote/default-blockquote/default-blockquote';
@import 'atoms/blockquote/author-blockquote/author-blockquote';
@import 'atoms/link/link';
@import 'atoms/headings/headings';
@import 'atoms/lede/lede';
@import 'atoms/meta/meta';
@import 'atoms/logo/logo';
@import 'atoms/notification/notification';
@import 'atoms/node/node';
@import 'atoms/burger/burger';
@import 'atoms/flash-message/flash-message';
@import 'atoms/table/table';
@import 'atoms/icon/icon';
@import 'atoms/audio/audio';

// Molecules
@import 'molecules/nav/horizontal-nav/horizontal-nav';
@import 'molecules/nav/vertical-nav/vertical-nav';
@import 'molecules/nav/mobile-nav/mobile-nav';
@import 'molecules/nav/breadcrumb/breadcrumb';
@import 'molecules/teaser/default-teaser/default-teaser';
@import 'molecules/cta/cta';
@import 'molecules/cart-tray/cart-tray';
@import 'molecules/summary-table/summary-table';
@import 'molecules/pagetitle/pagetitle';
@import 'molecules/footer/footer';
@import 'molecules/accordion/accordion';
@import 'molecules/feature/feature-image/feature-image.scss';
@import 'molecules/pagination/pagination';
@import 'molecules/slideshow/slideshow';
@import 'molecules/dropdown/dropdown';
@import 'molecules/cart-summary/cart-summary';

// Utilities
@import 'utilities/text-color/text-color';
@import 'utilities/uppercase';
@import 'utilities/text-center';
@import 'utilities/inverse';
@import 'utilities/spacing';
@import 'utilities/screen-reader-only';
@import 'utilities/trim';
@import 'utilities/visibility';


body.debug {
  &:after { // fixed elem that shows which bp the viewport is at
    background-color: rgba(0,0,0,.3);
    color: white;
    text-transform: uppercase;
    padding: 17px 25px;
    position: fixed;
    font-size: 15px;
    font-weight: 700;
    bottom: 0;
    left: 0;
    z-index: 999;
    @each $config in $breakpoints-map {
      $name: nth($config, 1);
      @include respond-to($name) {
          content: $name;

      }
    }
  }
}

.errors {
    li {
        background-color: palette('primary');
        color: white;
        padding: spacing(base);
    }
}

.is--disabled-block {
  pointer-events: none;
  opacity: 0.3;
  background-color: rgba(10,10,10,0.05);
}
