blockquote {
	font-weight: 500;
	font-size: rem-calc(fontsize(large));
	padding: 0;
	margin: 0;
	width: auto;
	position: relative;

    p {
        font-weight: 500;
        padding: 0;
        margin: 0;
        margin-bottom: spacing(base);
        line-height: 1.44;
        font-size: rem-calc(20);
        line-height: rem-calc(27);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

	&:before, &:after {
		position: absolute;
		color: palette('secondary');
		font-family: 'Frank Ruhl Libre', serif;
		font-size: rem-calc(40);

        @include respond-to(medium) {
            font-size: rem-calc(50);
            line-height: 0;
        }
	}

	&:before {
		top: - rem-calc(10);
		left: - rem-calc(20);
		content: '“';

        @include respond-to(medium) {
            top: rem-calc(25);
            left: - rem-calc(30);
        }
	}

	&:after {
		bottom: - rem-calc(10);
        right: - rem-calc(20);
		content: '”';

        @include respond-to(medium) {
            bottom: rem-calc(25);
            right: - rem-calc(30);
        }
	}

    & + cite {
        font-size: rem-calc(fontsize(small));
        line-height: rem-calc(21);
        font-style: normal;
        font-weight: 600;
    }
}
