// ==========================================================================
// Label Form Atom
// ==========================================================================

.label {
    display: block;
    margin-bottom: spacing(base);
    vertical-align: middle;
    font-weight: bold;
    font-size: rem-calc(fontsize(regular));
    color: palette('black');
    font-weight: bold;
}

label.label {
    cursor: pointer;
}
