.m-cart-summary {
	padding: spacing(double);
    margin-bottom: spacing(double);
    border: 1px dashed palette('tertiary');
    border-radius: rem-calc(25);

	@include respond-to(medium) {
		padding: spacing(triple);
	}
}

.m-cart-summary__heading {
    border-bottom: 1px dashed palette('tertiary');
    padding-bottom: spacing(base);

    @include respond-to(medium) {
        padding-bottom: spacing(double);
    }
}
