.m-cta {
	background: palette('lightgrey');
	padding: spacing(triple) 0;
    margin-top: spacing(double);

	@include respond-to(medium) {
		padding: spacing(quad) 0;
        margin-top: spacing(triple);
	}
}

.m-cta__title {
	margin-bottom: spacing(base);
}

.m-cta__btns {
	margin-top: spacing(double);

	a {
		margin: rem-calc(10 5 0 5);
		display: block;

		@include respond-to(small) {
			display: inline-block;
		}
	}
}
