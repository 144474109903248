// ==========================================================================
// Island Atom
// ==========================================================================

.island {
    background: white;
}

// Loop through each colour and generate a island for each
@each $color, $value in $colours-map {
  .island--#{$color} {
    background: $value;
  }
}

.island--dotted {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAAXNSR0IArs4c6QAAACZJREFUCB1jfPToyX8GIJCVlQZRcMAEZ6ExGP8DAZoYmItTB+kSANFnCAPnh80rAAAAAElFTkSuQmCC') 0 0 repeat white;
}
