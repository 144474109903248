.node {
	border-radius: 100%;
	border: 2px solid palette('grey');
	width: rem-calc(27);
	height: rem-calc(27);
	display: inline-block;
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: rem-calc(fontsize(small));
    font-weight: 800;

    @include respond-to(medium) {
    	width: rem-calc(35);
		height: rem-calc(35);
    }

    .chevron {
		border-width: rem-calc(2);
		width: rem-calc(6);
		height: rem-calc(6);
	}

	.chevron--left {
		margin-left: rem-calc(2);
	}

	.chevron--right {
		margin-right: rem-calc(2);
	}

    @include link-hover() {
    	background: palette('primary');
    	color: white;
    	border: 2px solid palette('primary');

    	.chevron {
    		border-color: white;
    	}
    }
}

.node.is--active {
	background: palette('primary');
	color: white;
	border: 2px solid palette('primary');

	.chevron {
		border-color: white;
	}
}

.node.is--disabled {
	color: palette('grey');

	.chevron {
		border-color: palette('grey');
	}

	@include link-hover() {
		background: none;
		color: palette('grey');
		border: 2px solid palette('grey');
		cursor: not-allowed;
	}
}
