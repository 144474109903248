.m-feature {
	background: white;
    border: 1px solid palette('tertiary');
    background: url('../assets/images/feature-bg.svg');
    background-size: cover;
    background-position: center center;
    border-left: 0;
    border-right: 0;
    margin-bottom: spacing(double);

    @include respond-to(medium) {
        margin-bottom: spacing(triple);
    }

    @media screen and (min-width: 1300px) {
        border-left: 1px solid palette('tertiary');
        border-right: 1px solid palette('tertiary');
    }

    p {
        color: palette('tertiaryhover');
    }
}

.m-feature__title {
	margin: 0;
	padding: 0;
}

.m-feature__image {
	margin-left: rem-calc(-$_wrapper-padding);
	margin-right: rem-calc(-$_wrapper-padding);
	background-size: cover;
	background-position: center center;
	width: calc(100% + #{2 * $_wrapper-padding});
	height: rem-calc(250);

	@include respond-to(small) {
		height: rem-calc(320);
	}

	@include respond-to(medium) {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		height: 100%;
	}
}

.m-feature__btns {
	.btn + .link {
		display: block;
		margin-top: spacing(base);

		@include respond-to(large) {
			margin-top: 0;
			margin-left: spacing(base);
			display: inline-block;
		}
	}
}
