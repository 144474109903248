.m-dropdown {
    position: relative;
    background: palette('black');
    text-align: left;
    display: none;
    min-width: rem-calc(150);
    padding: rem-calc(10 25);

    @include respond-to(medium) {
        z-index: 10;
        padding: spacing(double double base double);
        border-radius: rem-calc(3);
        position: absolute;
        top: rem-calc(54);
        left: 50%;
        width: rem-calc(276);
        margin-left: rem-calc(-138);
        text-align: center;

        &:before {
            content: "";
            position: absolute;
            z-index: 2;
            top: rem-calc(-16);
            left: 50%;
            margin-left: rem-calc(-8.5);
            width: 0;
            height: 0;
            border-left: rem-calc(17) solid transparent;
            border-right: rem-calc(17) solid transparent;
            border-bottom: rem-calc(16) solid palette('black');
        }

        &:after {
            content: "";
            height: rem-calc(20);
            background-color: white;
            position: absolute;
            z-index: 1;
            top: rem-calc(-20);
            left: 0;
            right: 0;
        }
    }

    @include respond-to(large) {
        top: rem-calc(58);
    }

    .m-horizontal-nav__item {
        display: none;
    }

    @include link-hover() {
        display: block;
    }

    &.is--open {
        display: block;
    }
}

.m-dropdown__item {
    display: block;
    padding: rem-calc(10 0);

    @include link() {
        background: none;
        color: white;
        text-decoration: none;
        font-size: rem-calc(fontsize(small));
    }

    @include respond-to(medium) {
        margin-bottom: spacing(base);
        padding: 0;

        @include link-hover() {
            color: white;
            text-decoration: underline;
        }
    }

    @include respond-to(large) {
        @include link() {
            font-size: rem-calc(fontsize(regular));
        }
    }
}

.m-dropdown__parent {
    position: relative;
    display: block;
    width: 100%;

    @include respond-to(medium) {
        width: auto;
        display: inline-block;

        @include link() {
            color: white;
            border-radius: rem-calc(20);

            .m-horizontal-nav__item {
                position: relative;
            }
        }

        @include link-hover() {

            > .m-dropdown {
                display: block;
            }
        }
    }
}
