.icon {
    max-width: 100%;
    height: rem-calc(100);
}



// Social icons
.icon--social {
    width: rem-calc(50);
    height: rem-calc(50);
    background-repeat: no-repeat;
    background-position: center center;
    font: 0/0 a;
    border: 2px solid;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;
}


$social-icons: (
  ('twitter', 'white'),
  ('twitter', 'primary'),
  ('facebook', 'white'),
  ('facebook', 'primary'),
);



@each $icon in $social-icons {
    $platform: nth($icon, 1);
    $colour: nth($icon, 2);

    .icon--#{$platform}-#{$colour} {
        background-image: url('../assets/images/social/#{$platform}-#{$colour}.svg');

        @if $colour == 'white' {
            border-color: white;
        } @else {
            border-color: palette($colour);
        }


    }
}
