.m-mobile-nav {
    text-align: left;
    padding: spacing(0 0);
    width: 100%;

    .m-dropdown {
        .m-mobile-nav__item {
            display: none;
        }

        .m-dropdown__item {
            border-bottom: 1px solid rgba(255,255,255,0.1);
            font-weight: 200;
            font-size: rem-calc(fontsize(regular));

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.m-mobile-nav__item {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: rem-calc(0 0 0 25);
    border-top: 1px solid white;
    &,
    & a {
        text-decoration: none;


        @include link() {
            color: palette('black');
            width: 100%;
            display: flex;
            align-items: center;
        }

        @include link-hover() {
            color: palette('primary');
        }
    }

    .chevron {
        margin-bottom: rem-calc(2);
        border-color: white;
    }
}

.mobile-nav__text {
    padding: rem-calc(15 0);
    &.is--active {
        @include link() {
            color: palette('primary');
        }
    }
}


.m-mobile-nav__item-expand {
    padding: rem-calc(15 20);
    height: 100%;
    text-align: center;
    margin-left: auto;
    background-color: palette('primary');
    cursor: pointer;

    &.is--open {
        background: palette('black');
    }
}
