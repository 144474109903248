// ==========================================================================
// Price List Form Element
// ==========================================================================

.m-summary-table {
    margin-bottom: spacing('double');
    width: 100%;
    text-align: left;

    tr {
        width: 100%;
    }

    td, th {
        padding-top: rem-calc(6);
        padding-bottom: rem-calc(6);
    }

    tfoot td,
    tfoot th {
        text-align: right;
    }
}

.m-summary-table--align-top {
    td, th {
        vertical-align: top;
    }
}

.m-summary-table__row {

}

.m-summary-table__divider {
    td, th {
        border-bottom: 1px dashed palette('tertiary');
        padding-bottom: spacing(double);
    }
    & + tr {
        td, th {
            padding-top: spacing(double);
        }
    }
}

.m-summary-table__row--disabled {
    td {
        color: palette('grey');
    }
}

.m-summary-table__description {
    width: 80%;
    font-weight: normal;
    padding-right: rem-calc(25);
}

.m-summary-table__caption,
.m-summary-table__price {
    width: 10%;
    font-weight: bold;
}

.m-summary-table__caption {
    width: 30%;
    padding-right: spacing(base);
    text-align: right;
}

.m-summary-table__price {
    text-align: right;
}

.m-summary-table__btn {
    width: 15%;
    padding-left: spacing(base);
}
