// @todo @shame rename this!
.u-text-center {
    text-align: center !important;
}

.u-text-right {
    text-align: right !important;
}

.u-text-right--medium {
    @include respond-to(medium) {
        text-align: right !important;
    }
}
