.m-cart-tray {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: spacing(double 0);
    box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    background: white;
    z-index: 99;
    @include respond-to(medium) {
        padding: spacing(triple 0);
    }
}

.m-cart-tray__price {
    display: inline-block;
    vertical-align: top;
}
