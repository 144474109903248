// ==========================================================================
// CHECKBOX
// ==========================================================================
//
.checkbox {
    display: block;
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
    margin-top: 5px;

    & + label {
        display: block;
        text-align: left;
        position: relative;
        padding-left: rem-calc(10);
        padding-left: rem-calc(26);
        margin-top: 10px;
        font-weight: normal;

        &:before {
            content: "";
            width: rem-calc(18);
            height: rem-calc(16);
            border: rem-calc(1) solid palette('primary');
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            font-size: rem-calc(12);
            text-align: center;
            content: ' ';
            padding-top: 2px;
            border-radius: rem-calc(2);
        }
    }

    &:checked + label {
        &:before {
            border-color: palette('primary');
            background-color: palette('primary');
            color: white;
            content: '\2714';
        }
    }

    &:focus + label {
        &:before {
            border-color: palette('primary');
        }
    }
}

.checkbox--inline {
    display: inline-block;

    & + label {
        display: inline-block;
        margin-right: rem-calc(15);
        margin-top: rem-calc(0);
    }
}
