
$breakpoints-map: (
  // Small
  'small': (480),
  // Medium
  'medium': (800),
  // Large
  'large': (960),
  // X-large
  'xlarge': (1200),
);
