.m-breadcrumb {

}

.m-breadcrumb__item {
    @include link() {
        font-weight: normal;
        line-height: 1.44;
    }

    &.is--active {
        @include link() {
            color: palette('black');
            text-decoration: none;
        }
    }
}

.m-breadcrumb__seperator {
    font-weight: 300;
}
