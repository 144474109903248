h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
    font-family: 'Frank Ruhl Libre', serif;
    font-weight: 900;
    color: palette('secondaryhover');
    margin: 0 0 rem-calc(15) 0;

    > a {
        @include link() {
            color: palette('secondaryhover');
            text-decoration: none;
        }

        @include link-hover() {
            color: palette('tertiaryhover');
        }
    }
}

h1, .h1 {
    font-size: rem-calc(52);
    line-height: rem-calc(43);
    font-size: rem-calc(fontsize(xxlarge));

    @include respond-to(medium) {
        font-size: rem-calc(fontsize(xxxlarge));
        line-height: rem-calc(57);
    }
}

h2, .h2 {
    font-size: rem-calc(fontsize(xlarge));
    line-height: rem-calc(32);

    @include respond-to(medium) {
        font-size: rem-calc(fontsize(xxlarge));
        line-height: rem-calc(45);
    }
}

h3, .h3 {
    font-size: rem-calc(fontsize(large));
    line-height: rem-calc(27);

    @include respond-to(medium) {
        font-size: rem-calc(fontsize(xlarge));
        line-height: rem-calc(33);
    }
}

h4, .h4 {
    font-size: rem-calc(fontsize(medium));
    line-height: rem-calc(24);

    @include respond-to(medium) {
        font-size: rem-calc(fontsize(large));
        line-height: rem-calc(31);
    }
}


h5, .h5,
h6, .h6 {
    font-size: rem-calc(fontsize(regular));
    line-height: rem-calc(24);

    @include respond-to(medium) {
        font-size: rem-calc(fontsize(medium));
        line-height: rem-calc(31);
    }
}
