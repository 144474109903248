@mixin inverse() {
    & {
        color: white;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
        color: white;
    }

    a {
    	color: white;

        &:hover {
        	border-color: white;
    	}
    }

    ul.list--styled {
        li {
            &:before {
                border-color: white;
            }
        }
    }

    .chevron {
        border-color: white;
    }

    .meta {
        color: white;
    }

    .link {
        @include link() {
            color: white;
        }

        @include link-hover() {
            color: palette('primary');
        }
    }

    .btn--secondary {
        @include link() {
            background-color: transparent;
            border-color: white;
            color: white;
        }

        @include link-hover() {
            border-color: palette('primary');
            color: palette('primary');
        }
    }
}

.u-inverse {
    @include inverse();
}
