@mixin chevron {
  display: inline-block;
  border-right: rem-calc(2) solid palette('primary');
  border-bottom: rem-calc(2) solid palette('primary');
  width: rem-calc(6);
  height: rem-calc(6);
  transform: rotate(-45deg);
}

.chevron {
	@include chevron;
}

.chevron--down {
	transform: rotate(45deg);
}

.chevron--up {
	transform: rotate(-135deg);
}

.chevron--left {
	transform: rotate(135deg);
}
