// ==========================================================================
// Input Form Atom
// ==========================================================================

.input {
    width: 100%;
    display: block;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: rem-calc(5);
    border: rem-calc(1) solid palette('grey');
    font-family: 'Lato', sans-serif;
    padding: spacing(13 15);
    font-size: rem-calc(fontsize(regular));
    font-weight: 300;
    margin-bottom: rem-calc(5);
    color: palette('black');

    @include placeholder {
        color: lighten(black, 65%);
    }


    &.has--error {
        border-color: palette('primary');;
        background-color: lighten(palette('primary'), 30%);
    }

    &.has--success {
        border-color: palette(green);
        color: paletet(green);
    }

    &[disabled] {
        background-color: palette('lightgrey');
    }

    &:focus {
        border-color: palette('black');
    }
}

.input[type="password"] {

    @include placeholder {
        display: none;
    }
}
